import * as React from "react";
import Control from "../../../components/control";
import Dropdown from "../../../components/dropdown";
import ImagePicker from "../../../components/image-picker";
import styles from "./item-detail.module.css";
import MutliSelect from "../../../components/multi-select";
import { flatten } from "lodash";

export default function ItemDetail({
  industries,
  suppliers,
  prevData,
  onSupplierChange,
}) {
  const [industry, setIndustry] = React.useState(prevData?.Item_Industry || []);

  const industryOptions = industries.map((industry) => ({
    value: industry._id,
    label: industry.Industry_Name,
  }));
  const categoryOptions = flatten(
    industries
      .filter((item) => industry.includes(item._id))
      .map((industry) =>
        industry.Categories.map((category) => ({
          value: category._id,
          label: category.Category_Name,
        })),
      ) || [],
  );

  const supplierOptions = suppliers.map((sup) => ({
    label: sup.Supplier_Name,
    value: sup._id.toString(),
  }));

  return (
    <section id="item-details">
      <div className={styles.container}>
        <div>
          <Control
            name="Item_Name"
            id="item-name"
            label="Name"
            required
            initialValue={prevData?.Item_Name}
            fullWidth
          />
          <div className={styles.spacer} />
          <Dropdown
            name="Item_Supplier"
            id="item-supplier"
            label="Supplier"
            required
            options={supplierOptions}
            placeholder="Select Supplier"
            value={prevData?.Item_Supplier}
            fullWidth
            onChange={onSupplierChange}
          />
        </div>
        <div>
          <MutliSelect
            name="Item_Industry"
            id="item-industry"
            label="Industry"
            required
            fullWidth
            options={industryOptions}
            placeholder="Select Industry"
            value={industry}
            onChange={setIndustry}
          />
          <div className={styles.spacer} />
          <MutliSelect
            name="Item_Category"
            id="item-category"
            label="Category"
            required
            fullWidth
            options={categoryOptions}
            placeholder="Select Category"
            defautValue={prevData?.Item_Category}
            key={industry.join("-")}
          />
        </div>
        <div>
          <Control
            name="Item_Description"
            id="item-description"
            label="Description"
            multiline
            rows={7}
            cols={25}
            initialValue={prevData?.Item_Description}
            fullWidth
          />
        </div>
      </div>
      <div className={styles.imagesContainer}>
        <ImagePicker
          name="Item_Image"
          id="item-image"
          label="Update Image"
          image={
            prevData?.Item_Image
              ? `${process.env.REACT_APP_IMAGE_PREFIX}/${prevData?.Item_Image}`
              : undefined
          }
        />
        <ImagePicker
          name="Item_Image_Large"
          id="item-image-large"
          label="Update Image Large"
          image={
            prevData?.Item_Image_Large
              ? `${process.env.REACT_APP_IMAGE_PREFIX}/${prevData?.Item_Image_Large}`
              : undefined
          }
        />
      </div>
    </section>
  );
}
