import * as React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import Palette from "../components/palette";
import styles from "./items.module.css";
import Radio from "../components/radio";
import { generateGetLoader } from "../lib/helpers";

export async function loader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get("page") || 1;
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/item/getAll?page=${page}&limit=25`,
    "items",
  )();
  return response;
}

function getItemsFromResponse(items) {
  return items?.items.map((item) => {
    const colours = {};
    const sizes = new Set();
    // let priceMin = Math.min();
    // let priceMax = Math.max();
    // let cPriceMin = Math.min();
    // let cPriceMax = Math.max();
    let stocked = false;

    item.Variants.forEach((variant) => {
      sizes.add(variant.Variant_Size);
      // priceMin = Math.min(priceMin, variant.Variant_Selling_Price);
      // priceMax = Math.max(priceMax, variant.Variant_Selling_Price);
      // cPriceMin = Math.min(cPriceMin, variant.Variant_Cost_Price);
      // cPriceMax = Math.max(cPriceMax, variant.Variant_Cost_Price);
      if (variant.Variant_Colour) {
        colours[variant.Variant_Colour.Colour_Code] = true;
      }
      if (variant.InventoryID?.isWarehouseItem) {
        stocked = true;
      }
    });

    const coloursList = Object.keys(colours);
    const renderedColurs =
      coloursList.length > 0 ? (
        <div className={styles.coloursContainer}>
          {coloursList.slice(0, 3).map((color) => (
            <Palette
              code={color}
              height={60}
              width={60}
              borderRadius={8}
              key={color}
            />
          ))}
          {coloursList.length > 3 && (
            <div className={styles.count}>+{coloursList.length - 3}</div>
          )}
        </div>
      ) : (
        <div className={styles.tableText}>Not Painted</div>
      );

    const renderedSizes = (
      <div className={styles.sizesContainer}>
        {Array.from(sizes).map((size) => (
          <div key={size}>{size}</div>
        ))}
      </div>
    );

    const image = item.Item_Image ? (
      <img
        src={`${process.env.REACT_APP_IMAGE_PREFIX}/${item.Item_Image}`}
        alt="item"
        className={styles.previewImage}
      />
    ) : (
      <div className={styles.previewImage} />
    );

    return [
      {
        value: encodeURI(
          `${item._id}?name=${item.Item_Name}&im=${item.Item_Image}&iml=${item.Item_Image_Large}`,
        ),
      },
      { value: image },
      { value: <span className={styles.tableText}>{item.Item_Name}</span> },
      { value: renderedColurs },
      { value: renderedSizes },
      {
        value: (
          <span className={styles.tableText}>
            {item.Item_Supplier?.Supplier_Name}
          </span>
        ),
      },
      {
        value: (
          <Radio
            defaultChecked={stocked}
            containerStyles={{ display: "block" }}
          />
        ),
        align: "right",
      },
    ];
  });
}

export default function Items() {
  const { items } = useLoaderData();
  const navigate = useNavigate();
  const currentPage = items.currentPage;
  const totalPages = items.totalPages;
  const [itemRows, setItemRows] = React.useState(getItemsFromResponse(items));

  function handleRowClick(id) {
    navigate(id);
  }

  function onDrop(rowIndex, afterIndex) {
    if (rowIndex === afterIndex) {
      return;
    } else if (rowIndex < afterIndex) {
      setItemRows((curr) => [
        ...curr.slice(0, rowIndex),
        ...curr.slice(rowIndex + 1, afterIndex + 1),
        curr[rowIndex],
        ...curr.slice(afterIndex + 1),
      ]);
    } else {
      setItemRows((curr) => [
        ...curr.slice(0, afterIndex),
        curr[rowIndex],
        ...curr.slice(afterIndex, rowIndex),
        ...curr.slice(rowIndex + 1),
      ]);
    }
  }

  React.useEffect(() => {
    setItemRows(getItemsFromResponse(items));
  }, [items]);

  return (
    <>
      <PageHeader text="Items" />
      <Table
        headers={[
          { name: "Item", width: "21%" },
          { name: "Item Name", width: "18%" },
          { name: "Colour", width: "18%" },
          { name: "Size", width: "18.5%" },
          { name: "Supplier", width: "15.6%" },
          { name: "Stocked", width: "8.9%" },
        ]}
        rows={itemRows}
        nextLink={
          currentPage < totalPages ? `?page=${currentPage + 1}` : undefined
        }
        prevLink={currentPage > 1 ? `?page=${currentPage - 1}` : undefined}
        currentPage={currentPage}
        totalPages={totalPages}
        onRowClick={handleRowClick}
        draggableRows
        onDrop={onDrop}
      />
    </>
  );
}
